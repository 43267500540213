import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ApplicantDetails } from "../components/ApplicantDetails";
import { RemoveIcon } from "../components/Icons";
import { ApplicantNotes } from "../components/ApplicantNotes";
import { ClickNotifier } from "../components/ClickNotifier";
import { isNotBlank } from "../utils/uiUtils";
import { cloneApplicant } from "./ProcessUtils";
import Tabs from "../components/Tabs";
import { ConfirmDialog } from "../components/ConfirmDialog";

export const ApplicantCard = ({
  formData,
  fieldPrefix,
  applicant,
  errors,
  removeApplicantAction,
  updateApplicantAction,
  offices,
  disabled = false
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const [applicantData, updateApplicantData] = useState(cloneApplicant(applicant));

  const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = useState(false);
  const [ongoingRemove, setOngoingRemove] = useState(false);

  const tabs = [
    {
      name: t("Process.Applicants.Tabs.Applicant"),
      current: activeTab === 1,
      index: 1,
    },
    {
      name: t("Process.Applicants.Tabs.Notes"),
      current: activeTab === 2,
      index: 2,
    },
  ];

  const handleOutsideClick = () => {
    if (hasChanges) {
      onEditConfirmed();
    }
  };

  const handleApplicantTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const onApplicantChange = (e) => {
    const { name, value } = e.target;
    const fieldSuffix = name.split(".")[1];
    updateApplicantField(fieldSuffix, value);
    setHasChanges(true);
  };

  const onApplicantBookingChange = (bookingData) => {
    updateApplicantData({ ...applicantData, ...bookingData });
    updateApplicantAction({ ...applicantData, ...bookingData });
  };

  const onApplicantInvitationResend = (name, value) => {
    updateApplicantData({ ...applicantData, [name]: value });
    updateApplicantAction({ ...applicantData, [name]: value });
  };

  const updateApplicantField = (name, value) => {
    updateApplicantData({ ...applicantData, [name]: value });
  };

  const onEditConfirmed = () => {
    updateApplicantAction(applicantData);
    setHasChanges(false);
  };

  const onApplicantRemove = () => {
    setShowConfirmRemoveDialog(false);
    setOngoingRemove(false);
    removeApplicantAction(applicantData.ordinal);
  };

  const confirmDelete = () => {
    setShowConfirmRemoveDialog(true);
  };

  return (
    <>
      <ClickNotifier outOfComponentClickAction={handleOutsideClick}>
        <div className="col-span-1 gap-x-8 gap-y-10 border-b border-gray-900/10 bg-white shadow-sm ring-2 ring-gray-900/5 sm:rounded-xl w-full">
          <div className="w-full text-base flex items-center justify-between pl-4 pr-4 pt-4">
            <div className="flex items-start">
              <Tabs
                tabs={tabs}
                action={handleApplicantTabClick}
                showIcon={false}
                theme="pills"
              />
              {activeTab === 1 && isNotBlank(applicantData.notes) && (
                <div className="text-red-600 pt-2 text-sm">
                  {t("Process.Applicants.Tabs.Remarks")}
                </div>
              )}
            </div>
            <div className="flex items-center space-x-4">
              {!disabled && <RemoveIcon action={confirmDelete} />}
            </div>
          </div>
          {activeTab === 1 && (
            <ApplicantDetails
              formData={formData}
              applicant={applicantData}
              fieldPrefix={fieldPrefix}
              handleChange={(event) => onApplicantChange(event)}
              handleBookingChange={onApplicantBookingChange}
              handleInvitationResend={onApplicantInvitationResend}
              offices={offices}
              errors={errors}
              disabled={disabled}
            />
          )}
          {activeTab === 2 && (
            <ApplicantNotes
              fieldPrefix={fieldPrefix}
              label={t("Process.Properties.ApplicantNotes")}
              value={applicantData.notes}
              handleChange={(event) => onApplicantChange(event)}
              errors={errors}
              disabled={disabled}
            />
          )}
        </div>
      </ClickNotifier>
      <ConfirmDialog
        title={t("Process.Applicants.Actions.Remove")}
        content={t("Process.Applicants.Actions.ConfirmRemoval")}
        confirmButtonLabel={t("Actions.Delete")}
        open={showConfirmRemoveDialog}
        setOpen={setShowConfirmRemoveDialog}
        action={onApplicantRemove}
        ongoingAction={ongoingRemove}
      />
    </>
  );
};
