export const apiRequest = {
  apiUsers: {
    usersAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/users`,
    searchUsersAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/search/email`,
  },
  apiProcesses: {
    processesAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/processes`,
  },
  apiCustomers: {
    customersAdminSearchEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/customers/search`,
  },
  apiChangeHistory: {
    processesChangeHistoryAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/changeHistory`,
  },
  apiCriteriaTemplates: {
    criteriaTemplatesAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/criteriaTemplates`,
  },
  apiOffices: {
    officesAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/offices`,
  },
  apiAssessments: {
    testProvidersAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/assessments/testProviders`,
    translationsAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/assessments/translations`,
  },
  apiCandidates: {
    candidatesAdminEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/candidates`,
  },
  apiSearch: {
    assessmentSearchEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/search/assessmentLogs`,
    assessmentLogDownloadEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/search/assessmentLogs/download`,
    textSearchEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/search`,
  },
  apiVersion: {
    versionInfoEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/version`
  },
  apiBookings: {
    bookingEndpoint: `${process.env.REACT_APP_API_ADDRESS}/api/admin/bookings`,
  }
};
