import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-center mt-24">
        <div className="text-center">
          <p className="mt-2 text-sm text-red-600">
            {t("Login.AccessDeniedError")}
          </p>
          <p className="mt-2 text-sm text-red-600">
            {t("Login.ContactSupport")}
          </p>
        </div>
      </div>
    </>
  );
};
