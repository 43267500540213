import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Attachment } from "../../components/Attachment";
import { FileUpload } from "../../components/FileUpload";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { deleteAssessmentReport } from "../../api";
import { isEmptyJson } from "../../utils/uiUtils";

export const AssessmentReports = ({
  title,
  processId,
  applicantId,
  assessmentReportUri,
  assessmentReports,
  assessmentReportChanged,
  assessmentReportRemoved,
  updateAvailable
}) => {
  const { t } = useTranslation();

  const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = useState(false);
  const [ongoingRemove, setOngoingRemove] = useState(false);
  const [removalError, setRemovalError] = useState(false);
  const [currentReport, setCurrentReport] = useState({});

  const preRemoveActions = (report) => {
    setCurrentReport(report);
    setShowConfirmRemoveDialog(true);
  };

  const removeEvaluationReport = async () => {
    if (isEmptyJson(currentReport)) {
      setRemovalError(true);
      setShowConfirmRemoveDialog(false);
      return;
    }

    setOngoingRemove(true);
    setRemovalError(false);
    const response = await deleteAssessmentReport(processId, applicantId, currentReport.id).catch(
      (e) => {
        console.log(e);
        setRemovalError(true);
      },
    );
    if (response?.deleted === true) {
      assessmentReportRemoved(currentReport.id);
      setRemovalError(false);
    } else {
      setRemovalError(true);
    }
    setShowConfirmRemoveDialog(false);
    setOngoingRemove(false);
  };

  return (
    <>
      <div className="mb-2">{title}</div>
      {assessmentReports &&
        assessmentReports.map((report, index) => (
          <div className="text-sm mt-1 group flex items-start" key={`assessment_report_${index}_key`}>
            <Attachment
              item={report}
              removeAction={() => preRemoveActions(report)}
              removeEnabled={updateAvailable}
            />
          </div>
        ))}
      <ConfirmDialog
        title={t("ProcessProgress.AssessmentReport.Remove")}
        content={t("ProcessProgress.AssessmentReport.ConfirmRemoval")}
        confirmButtonLabel={t("Actions.Delete")}
        open={showConfirmRemoveDialog}
        setOpen={setShowConfirmRemoveDialog}
        action={removeEvaluationReport}
        ongoingAction={ongoingRemove}
      />
      { updateAvailable && (
        <FileUpload
          id={`applicant_${applicantId}_assessment_report`}
          label={t("ProcessProgress.AssessmentReport.Add")}
          sizeLimit={10}
          uri={assessmentReportUri}
          fileType={"application/pdf"}
          attachmentType={"AssessmentReport"}
          action={assessmentReportChanged}
        />
      )}
      { !updateAvailable && isEmptyJson(assessmentReports) && (
        <div className="text-sm mt-1 group flex items-start">
          {t("ProcessProgress.AssessmentReport.NoneAdded")}
        </div>
      )}

      {removalError && (
        <p className="mt-2 text-sm text-red-600">
          {t("ProcessProgress.AssessmentReport.RemoveFailed")}
        </p>
      )}
    </>
  );
};
