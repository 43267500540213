import { useTranslation } from "react-i18next";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";

export const Pagination = ({
  page,
  totalPages,
  showNextPage,
  showPrevPage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      {totalPages > 1 && (
        <>
          <button
            className={
              (page === 0 ? "text-gray-600" : "text-mps-ultramarine") +
              " flex text-sm"
            }
            onClick={showPrevPage}
          >
            <ArrowLongLeftIcon className="mr-1 h-5 w-5" />
            {t("Actions.Previous")}
          </button>

          <div className="text-sm">
            {t("Search.AssessmentLog.Page")} {page + 1 + "/" + totalPages}
          </div>
          
          <button
            className={
              (page + 1 === totalPages
                ? "text-gray-600"
                : "text-mps-ultramarine") + " flex text-sm"
            }
            onClick={showNextPage}
          >
            {t("Actions.Next")} <ArrowLongRightIcon className="ml-1 h-5 w-5" />
          </button>
        </>
      )}
    </div>
  );
};
