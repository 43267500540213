import { useTranslation } from "react-i18next";
import { TextArea } from "../components/TextArea";
import { InterviewType } from "../components/InterviewType";
import { getFieldError } from "./ProcessUtils";
import { ToggleOnLeft } from "../components/Toggle";

export const IndividualSimulationCard = ({ position, item, updateAction, errors, disabled }) => {
    const { t } = useTranslation();

    const fieldPrefix = `individualSimulations[${position}]`;

    const handleChange = (event, fieldSuffix) => {
        updateAction(item.ordinal, fieldSuffix, event.target.value);
    }

    const handleToggle = (value) => {
        updateAction(item.ordinal, "showInPortal", value);
    }

    const getError = (fieldSuffix) => {
        return getFieldError(errors, fieldPrefix, fieldSuffix);
    }

    return (
        <>
            <div className="col-span-full">
                <ToggleOnLeft label={t("Process.Interviews.Section3.ShowInPortal")} value={item.showInPortal ?? false} action={handleToggle} disabled={disabled}/>
            </div>
            <div className="col-span-full">
                <InterviewType
                    label={ t("Process.Properties.IndividualSimulationType") }
                    item={ item }
                    fieldPrefix={ fieldPrefix }
                    updateAction={ (event) => handleChange(event,"interviewType") }
                    includeNoInterview={ true }
                    includeIndividualSimulation={ true }
                    noInterviewLabel={ t("Process.Properties.IndividualSimulationNone") }
                    disabled={ disabled }
                />
            </div>

            <div className="col-span-full">
                <TextArea
                    label={ t("Process.Properties.IndividualSimulationNotes") }
                    id={ `${fieldPrefix}.notes` }
                    name={ `${fieldPrefix}.notes` }
                    value={ item.notes }
                    action={ (event) => handleChange(event,"notes") }
                    rows={ 4 }
                    error={ getError("notes") }
                    disabled={ disabled }
                />
            </div>
        </>
    );
};
