import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Assessment } from "./Assessment";

export const Provider = ({ provider, action }) => {
  const { t } = useTranslation();
  const [showAssessments, setShowAssessments] = useState(false);

  const updateAssessment = (assessmentId, translations) => {
    const index = provider.assessmentTranslations.findIndex((a) => a.assessmentId === assessmentId);
    const updatedTranslations = provider.assessmentTranslations.map((a, i) => {
      return i === index ? { ...a, translations: translations } : a;
    });
    action(provider.id, { ...provider, assessmentTranslations: updatedTranslations });
  };

  const toggleAssessments = () => {
    setShowAssessments(!showAssessments);
  };

  const text = showAssessments ? t("Translations.HideAssessments") : t("Translations.ShowAssessments");

  return (
    <div className="container rounded-lg bg-white shadow p-4 mb-6 divide-y divide-gray-200 overflow-hidden">
      <div className="flex justify-between align-middle">
        {provider.name}
        <span
          className="text-xs text-mps-ultramarine hover:underline"
          onClick={toggleAssessments}
        >
          {text}
        </span>
      </div>
      {showAssessments && (
        <div className="pt-4">
          {provider.assessmentTranslations.map((item) => (
            <Assessment
              key={item.assessmentId}
              assessment={item}
              action={updateAssessment}
            />
          ))}
        </div>
      )}
    </div>
  );
};
