import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, createSearchParams, Link } from "react-router-dom";
import { PencilIcon} from "@heroicons/react/24/solid";
import { getProcessStatus } from "../api";
import { Overlay } from "../components/Overlay";
import { isEmptyJson } from "../utils/uiUtils";
import { GrayPill, GreenPill, WarnPill } from "../components/Pills";
import { ApplicantStatusCard } from "./components/ApplicantStatusCard";
import BreadCrumbs from "../components/BreadCrumbs";

export function ProcessStatus() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { id } = useParams();
  const [showOverlay, setShowOverlay] = useState(false);
  const [process, setProcess] = useState([]);

  const breadcrumbs = [
    { label: t("BreadCrumbs.Progress"), url: window.location.pathname },
    { label: "", url: "/" },
  ];

  useEffect(() => {
    const loadingErrorParams = [["error", "ProcessNotFound"]];

    const fetchData = async () => {
      setShowOverlay(true);
      await getProcessStatus(id)
        .then((response) => {
          setProcess(response);
        })
        .catch((e) => {
          console.log(e);
          navigate({
            pathname: "/notFound",
            search: `?${createSearchParams(loadingErrorParams)}`,
          });
        })
        .finally(() => {
          setShowOverlay(false);
        });
    };

    fetchData().catch(console.error);
  }, [id, navigate]);

  return (
    <AuthenticatedTemplate>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <BreadCrumbs breadcrumbs={breadcrumbs} />
          <div className="space-y-2 mt-6">
            <div className="flex items-center justify-between">
              <div className="flex content-start">
                {!isEmptyJson(process) && (
                  <>
                    <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                      {process.jobTitle} / {process.customer}
                    </h2>
                    <div className="">
                      <Link
                        to={`/process/${id}/edit`}
                        className="font-medium text-mps-ultramarine dark:text-mps-ultramarine hover:underline pr-4"
                      >
                        <PencilIcon
                          className="ml-4 mt-3 h-5 w-5 flex-shrink-0 text-mps-ultramarine"
                          aria-hidden="true"
                          title={t("Processes.Actions.Edit")}
                        />
                      </Link>
                    </div>
                    <div className="pt-2 pl-2">
                      {process.processState === "Published" ? (
                        <GreenPill
                          text={t("Process.State.Published")}
                          includeDot={true}
                        />
                      ) : process.processState === "Draft" ? (
                        <WarnPill text={t("Processes.State.Draft")} />
                      ) : process.processState === "Archived" ? (
                        <GrayPill text={t("Processes.State.Archived")} />
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="container mx-auto">
              <div className="grid grid-cols-2 gap-8">
                {process.applicants &&
                  process.applicants.map((applicant) => (
                    <div
                      className="rounded-lg bg-white shadow divide-y divide-gray-200 text-base mt-2 mb-2"
                      key={`applicant_status_key_${applicant.id}`}
                    >
                      <ApplicantStatusCard
                        processId={id}
                        processState={process.processState}
                        applicantId={applicant.id}
                        applicantName={`${applicant.firstName} ${applicant.lastName}`}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <Overlay isActive={showOverlay} />
      </div>
    </AuthenticatedTemplate>
  );
}
