import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { getCandidateById, getCandidateProcesses } from "../api";
import { Overlay } from "../components/Overlay";
import { CandidateCard } from "./components/CandidateCard";
import { CandidateProgress } from "./components/CandidateProgress";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { ProcessesListView } from "../process/components/ProcessesListView";
import { formatAsLocalDateTime, isNotBlank } from "../utils/uiUtils";

export function CandidateView() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [processesLoading, setProcessesLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [candidate, setCandidate] = useState({});
  const [processes, setProcesses] = useState([]);

  useEffect(() => {
    const loadingErrorParams = [["error", "CandidateNotFound"]];
    const fetchData = async () => {
      setShowOverlay(true);
      await getCandidateById(id)
        .then((response) => {
          setCandidate(response);
        })
        .catch((e) => {
          console.log(e);
          navigate({
            pathname: "/",
            search: `?${createSearchParams(loadingErrorParams)}`,
          });
        })
        .finally(() => {
          setShowOverlay(false);
          setIsLoading(false);
        });
    };
    if (isLoading) {
      fetchData().catch(console.error);
    }
  }, [id, candidate, navigate, isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      await getCandidateProcesses(id)
        .then((response) => {
          setProcesses(response);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setProcessesLoading(false);
        });
    };
    if (processesLoading) {
      fetchData().catch(console.error);
    }
  }, [id, processesLoading, processes]);

  return (
    <AuthenticatedTemplate>
      <div className="container mx-auto">
        <div className="space-y-2 mt-6">
          <div className="flex items-center justify-between">
            <div className="flex content-start">
              {!isLoading && (
                <>
                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    {candidate.lastName}, {candidate.firstName}
                  </h1>
                  {isNotBlank(candidate.lastLoginTime) ? (
                    <span className="text-gray-700 text-sm ml-4 mt-2.5">
                      {t("Candidate.LastLoginTime")}:{" "}
                      {formatAsLocalDateTime(candidate.lastLoginTime)}
                    </span>
                  ) : (
                    <span className="text-gray-700 text-sm ml-4 mt-2.5">
                      {t("Candidate.NoLoginTimeAvailable")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-2 align-top mt-6">
          <div className="w-1/2 px-2">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight w-full">
              {t("Candidate.Title.Info")}
            </h2>
            <div className="mt-4">
              {!isLoading && (
                <CandidateCard candidateId={id} candidate={candidate} />
              )}
            </div>
          </div>
          <div className="w-1/2 px-2">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight w-full">
              {t("Candidate.TestResults.Title")}
            </h2>
            <div className="mt-4">
              {!isLoading && <CandidateProgress candidateId={id} />}
            </div>
          </div>
        </div>

        <div className="w-full mt-6 align-top">
          <div className="">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight w-full">
              {t("Candidate.Title.Processes")}
            </h2>
            <div className="mt-4">
              {processesLoading ? (
                <LoadingIndicator type="small" />
              ) : (
                <ProcessesListView processes={processes} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Overlay isActive={showOverlay} />
    </AuthenticatedTemplate>
  );
}
