import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import {
    classNames,
    formatAsLocalDateTime,
    getItemWithLatestTimestamp,
    isEmptyJson,
    isNotBlank
} from "../utils/uiUtils";

export default function ChangeHistory({ changeHistory }) {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const currentTime = new Date();
  const username = account.username;

  const created = changeHistory.entries.find(
    (entry) => entry.name === "created",
  );

  const latestEntry = getItemWithLatestTimestamp(changeHistory.entries);

  const recentEntries = changeHistory.entries.filter((entry) => {
    const entryTime = new Date(entry.timestamp);
    const timeDifference = currentTime - entryTime;
    return timeDifference <= 15 * 60 * 1000;
  });

  const userName = (user) => {
      if (isNotBlank(user.name)) {
          return user.name;
      }
      return user.email;
  }

  const changeDetails = (entry) => {
    if (entry === undefined) {
      return "-";
    }

    return `${userName(entry.user)} | ${formatAsLocalDateTime(entry.timestamp)}`;
  };

  const otherUserEdited = () => {
    if (isEmptyJson(recentEntries)) {
      return "";
    }
    let otherEdits = recentEntries.filter((e) => e.user.email !== username);
    if (isEmptyJson(otherEdits)) {
      return "";
    }

    let latestBySomeoneElse = getItemWithLatestTimestamp(otherEdits);

    if (latestBySomeoneElse.user.email === latestEntry.user.email) {
      return "";
    }

    return changeDetails(latestBySomeoneElse);
  };

  return (
    <div className="flex items-center font-medium mt-4 mb-4">
      <InformationCircleIcon
        className="h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <span className="text-gray-500 ml-2 text-sm">
        {t("ChangeHistory.Created")}: {changeDetails(created)}
      </span>
      <PencilIcon
        className="ml-4 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <span className="text-gray-500 ml-2 text-sm">
        {t("ChangeHistory.Modified")}: {changeDetails(latestEntry)}
      </span>
      <span
        className={classNames('text-gray-500 ml-2 text-sm pl-1 pr-1 rounded-md bg-mps-sun bg-opacity-50')}
      >
        {otherUserEdited()}
      </span>
    </div>
  );
}
