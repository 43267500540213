import upperFirst from "lodash.upperfirst";
import uniq from "lodash.uniq";
import { add } from "date-fns";

const isoDateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
const timeFormatRegex = /^\d{2}:\d{2}:\d{2}$/;

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function isNotBlank(value) {
    return !(isBlank(value));
}

export function isBlank(value) {
    return !value || value.trim() === '';
}

export function isEmptyArray(jsonArray) {
    if (jsonArray === undefined) {
        return true;
    }
    return !(Array.isArray(jsonArray) && jsonArray.length > 0);
}

export function isEmptyJson(json) {
    if (json === undefined) {
        return true;
    }
    return !(Object.keys(json).length > 0 && Object.values(json).some(value => !!value));
}

export function isPositiveOrZero(value) {
    return !isNaN(value) && Math.sign(value) >= 0;
}

export function highlightByFilter(text, filterTerm) {
    if (isBlank(filterTerm)) {
        return text;
    }

    const regex = new RegExp(`(${filterTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
        regex.test(part) ? <span key={index} className="bg-amber-300">{part}</span> : part
    );
}

export function isMoreThanDaysFromToday(dateStr, days) {
    if (isNotBlank(dateStr) && isoDateFormatRegex.test(dateStr)) {
        const userDate = new Date(dateStr);
        const today = new Date();
        const daysFrom = new Date(today.setDate(today.getDate() + days));
        return userDate > daysFrom;
    }
    return false;
}

export function formatAsLocalDate(dateStr) {
    if (isNotBlank(dateStr) && isoDateFormatRegex.test(dateStr)) {
        return new Date(dateStr).toLocaleDateString('fi-FI', { month: '2-digit', day: '2-digit', year: 'numeric' });
    }
    return dateStr;
}

export function formatAsLocalDateTime(dateStr) {
    if (isNotBlank(dateStr)) {
        const timestamp = new Date(dateStr);

        return timestamp.toLocaleString('fi-FI', {
            timeZone: 'Europe/Helsinki',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }
    return dateStr;
}

export function formatAsIsoDate(dateStr) {
    if (isNotBlank(dateStr)) {
        const parts = dateStr.split('.');
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month}-${day}`;
        }
    }
    return dateStr;
}

export function formatDateAsIsoDate(date) {
    if (date === undefined || date === null) {
        return '';
    }
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - (offset * 60 * 1000));
    return localDate.toISOString().split('T')[0];
}

export function formatTime(timeStr) {
    if (isNotBlank(timeStr) && timeFormatRegex.test(timeStr)) {
        const [hours, minutes] = timeStr.split(':');
        return `${hours}:${minutes}`;
    }
    return timeStr;
}

export function formatAsIsoTime(timeStr) {
    if (isNotBlank(timeStr)) {
        const parts = timeStr.split(':');
        if (parts.length === 2) {
            const [hours, minutes] = parts;
            return `${hours}:${minutes}:00`;
        }
    }
    return timeStr;
}

export function getTomorrowsDateAsISO() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
}


export function toTitleCase(str) {
    if (isNotBlank(str)) {
        return upperFirst(str);
    }
    return str;
}

export function removeFromArray(arr, value){
    return arr.filter(v => v !== value);
}

export function filterSelected(jsonArray, selectedItems){
    return jsonArray?.filter(item => selectedItems?.includes(item.id)) || [];
}

export function getSelectedIds(items){
    return uniq(items?.map(item => (
        item.id
    )));
}

export function copyObject(obj) {
    if (obj === undefined || obj === null) {
        return obj;
    }
    return JSON.parse(JSON.stringify(obj));
}

export function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
}

export function dateTimeToLocalDate(datetimeValue) {
    if (datetimeValue === undefined) {
        return;
    }
    const timestamp = new Date(datetimeValue);

    return timestamp.toLocaleString('fi-FI', {
        timeZone: 'Europe/Helsinki',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    })
}

export function getBookingLabel(date, time, duration) {
  const dateTime = new Date(date + "T" + time);
  const endDateTime = add(dateTime, { minutes: duration });

  const startDate = new Date(dateTime).toLocaleString("fi-FI", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const startTime = new Date(dateTime).toLocaleString("fi-FI", {
    hour: "numeric",
    minute: "numeric",
  });

  const endTime = new Date(endDateTime).toLocaleTimeString("fi-FI", {
    hour: "numeric",
    minute: "numeric",
  });

  return `${startDate} klo ${startTime} - ${endTime}`;
}

export function sortEntriesByDate(data, propertyKey, sortOrder) {
    function compareDateTime(a, b) {
        const dateA = a[propertyKey].date || '';
        const dateB = b[propertyKey].date || '';
        const timeA = a[propertyKey].time || '';
        const timeB = b[propertyKey].time || '';

        const dateTimeA = dateA + ' ' + timeA;
        const dateTimeB = dateB + ' ' + timeB;

        if (dateTimeA === dateTimeB) return 0;
        if (sortOrder === 'asc') {
            return dateTimeA.localeCompare(dateTimeB);
        } else {
            return dateTimeB.localeCompare(dateTimeA);
        }
    }

    let entriesWithDateTime = [];
    let entriesWithoutDateTime = [];

    data.forEach(entry => {
        if (entry[propertyKey] && (entry[propertyKey].date || entry[propertyKey].time)) {
            entriesWithDateTime.push(entry);
        } else {
            entriesWithoutDateTime.push(entry);
        }
    });

    entriesWithDateTime.sort(compareDateTime);

    return entriesWithDateTime.concat(entriesWithoutDateTime)
}

export function sortEntriesByEmail(data, propertyKey, sortOrder) {
    return data.sort((a, b) => {
        const emailA = (a[propertyKey] && a[propertyKey][0] && a[propertyKey][0].email) || "";
        const emailB = (b[propertyKey] && b[propertyKey][0] && b[propertyKey][0].email) || "";

        if (sortOrder === 'asc') {
            return emailA.localeCompare(emailB);
        } else {
            return emailB.localeCompare(emailA);
        }
    });
}

export function getItemWithLatestTimestamp(items) {
    return items.reduce((latest, entry) => {
        return new Date(entry.timestamp) > new Date(latest.timestamp)
            ? entry
            : latest;
    }, items[0]);
}
