import { useTranslation } from "react-i18next";
import { MultiSelectBox } from "../../components/MultiSelectBox";
import { Checkbox } from "../../components/Checkbox";
import { Button } from "../../components/Button";
import { DateRangePicker } from "../../components/DateRangePicker";
import { useEffect, useState } from "react";
import { getOffices } from "../../api";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { SelectBox } from "../../components/SelectBox";

const pageSizes = [
  { id: 0, name: 25 },
  { id: 1, name: 50 },
  { id: 2, name: 75 },
  { id: 3, name: 100 },
];

export function AssessmentFilters({
  data,
  updateFiltersAction,
  applyFiltersAction,
  downLoadAction,
}) {
  const { t } = useTranslation();
  const [officesLoading, setOfficesLoading] = useState(true);
  const [offices, setOffices] = useState([]);

  const handleSelectChange = (selectedItems) => {
    updateFiltersAction("offices", selectedItems);
  };

  const dateFiltersChange = (name, value) => {
    updateFiltersAction(name, value);
  };

  const interviewFiltersChange = (event) => {
    updateFiltersAction(event.target.name, event.target.checked);
  };

  const pageSizeFiltersChange = (event) => {
    updateFiltersAction("pageSize", pageSizes[event.target.value].name);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getOffices()
        .then((response) => {
          setOffices(response);
        })
        .catch((error) => {
          console.log(error);
          setOffices([]);
        })
        .finally(() => {
          setOfficesLoading(false);
        });
    };
    if (officesLoading) {
      fetchData().catch(console.error);
    }
  }, [offices, officesLoading]);

  return (
    <div className="inline-block min-w-full align-middle">
      <table className="min-w-full">
        <thead>
          <tr>
            <th
              scope="col"
              className="pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              {t("Search.AssessmentLog.Filters.Timespan")}
            </th>
            <th
              scope="col"
              className="px-3 text-left text-sm font-semibold text-gray-900"
            >
              {t("Search.AssessmentLog.Filters.Offices")}
            </th>
            <th
              scope="col"
              className="px-3 text-left text-sm font-semibold text-gray-900"
            >
              {t("Search.AssessmentLog.Filters.Include")}
            </th>
            <th
              scope="col"
              className="px-3 text-left text-sm font-semibold text-gray-900"
            >
              {t("Search.AssessmentLog.Filters.PageSize")}
            </th>
            <th
              scope="col"
              className="px-3 text-left text-sm font-semibold text-gray-900"
            >
              {" "}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="pr-3 text-sm sm:pl-0 align-top pt-2">
              <DateRangePicker
                startDateValue={data.fromDate}
                startDateName="fromDate"
                endDateValue={data.toDate}
                endDateName="toDate"
                updateAction={dateFiltersChange}
              />
            </td>
            <td className="px-3 text-sm text-gray-500 align-top left-0 top-0">
              {officesLoading && (
                <div>
                  <LoadingIndicator type="small" />{" "}
                  <span className="pl-3">{t("Overlay.Loading")}</span>
                </div>
              )}
              {offices && offices.length > 0 && (
                <div className="w-48">
                  <MultiSelectBox
                    id={`offices_filter`}
                    options={offices || []}
                    required={false}
                    action={handleSelectChange}
                    selectedItems={data.offices}
                    nothingSelectedLabel={t(
                      "Search.AssessmentLog.Filters.AllOffices"
                    )}
                  />
                </div>
              )}
            </td>
            <td className="whitespace-nowrap px-3 text-sm text-gray-500 align-middle space-x-2">
              <Checkbox
                id={"interviews"}
                name={"interviews"}
                label={t("Search.AssessmentLog.Filters.Interviews")}
                value="includeInterviews"
                changeAction={interviewFiltersChange}
                isChecked={data.interviews}
              />
              <Checkbox
                id={"groupSimulations"}
                name={"groupSimulations"}
                label={t("Search.AssessmentLog.Filters.GroupSimulations")}
                value="groupSimulations"
                changeAction={interviewFiltersChange}
                isChecked={data.groupSimulations}
              />
              <Checkbox
                id={"individualSimulations"}
                name={"individualSimulations"}
                label={t("Search.AssessmentLog.Filters.IndividualSimulation")}
                value="individualSimulations"
                changeAction={interviewFiltersChange}
                isChecked={data.individualSimulations}
              />
            </td>
            <td className="whitespace-nowrap text-sm text-gray-500 align-middle space-x-2">
              <SelectBox
                id="pageSize"
                options={pageSizes}
                action={pageSizeFiltersChange}
                selected={0}
              />
            </td>
            <td className="whitespace-nowrap px-3 text-sm text-gray-500 align-middle text-right space-x-2">
              <Button
                text={t("Search.AssessmentLog.Filters.Update")}
                icon={"UpdateIcon"}
                type="secondary"
                action={(event) => {
                  updateFiltersAction("page", 0);
                  applyFiltersAction(event);
                }}
              />
              <Button
                text={t("Search.AssessmentLog.Filters.DownloadCsv")}
                icon={"DownloadIcon"}
                type="secondary"
                action={(event) => downLoadAction(event)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
