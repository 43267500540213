import { getTomorrowsDateAsISO } from "../utils/uiUtils";
import { DatePickerField } from "./DatePickerField";
import { useTranslation } from "react-i18next";

export function DateRangePicker({
  startDateValue,
  startDateName,
  endDateValue,
  endDateName,
  updateAction,
}) {
  const { t } = useTranslation();

  const startDate = startDateValue || getTomorrowsDateAsISO();
  const endDate = endDateValue || getTomorrowsDateAsISO();
  const handleStartDateChange = (event) => {
    updateAction(startDateName, event.target.value);
  };

  const handleEndDateChange = (event) => {
    updateAction(endDateName, event.target.value);
  };

  return (
    <div className="flex items-center">
      <div className="relative">
        <DatePickerField
          id="startDate"
          name="startDate"
          value={startDate}
          placeholder={t("Process.Applicants.Placeholders.Date")}
          updateAction={handleStartDateChange}
        />
      </div>
      <span className="mx-1 text-gray-500">-</span>
      <div className="relative">
        <DatePickerField
          id="endDate"
          name="endDate"
          value={endDate}
          placeholder={t("Process.Applicants.Placeholders.Date")}
          updateAction={handleEndDateChange}
        />
      </div>
    </div>
  );
}
