import React from "react";

export const InputPrefix = ({ id, lang, value, action }) => {
  const handleChange = (event) => {
    action(lang, event.target.value);
  };

  return (
    <div>
      <div className="mt-2 flex rounded-md shadow-sm">
        <span className="inline-flex items-center w-10 rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 bg-gray-50 sm:text-sm">
          {lang.toUpperCase()}
        </span>
        <input
          type="text"
          name={id}
          id={id}
          value={value ?? ""}
          onChange={handleChange}
          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
};
