import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex justify-center mt-24">
                <div className="text-center">
                    <p className="mt-2 text-sm text-red-600">
                        {t("NotFound.ResourceNotFound")}
                    </p>
                    <p className="mt-2 text-sm text-gray-900">
                        {t("NotFound.ContactSupport")}
                    </p>
                </div>
            </div>
        </>
    );
};
