import { SortIcon } from "../../components/Icons";

export function SortableColumn({ label, sortConfig, selectedKey, action }) {
  return (
    <div className="flex items-center space-x-2">
      <span className="cursor-pointer pl-1" onClick={() => action(selectedKey)}>
        {label}
      </span>
      <SortIcon active={sortConfig.key === selectedKey} action={() => action(selectedKey)} />
    </div>
  );
}
