import { useState } from "react";
import { useTranslation } from "react-i18next";
import { classNames, isEmptyArray, isEmptyJson } from "../utils/uiUtils";
import { MultiSelectBox } from "./MultiSelectBox";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export const Assessment = ({
  providerId,
  assessment,
  updateAction,
  updateOptionsAction,
  updateReportsAction,
  data,
  disabled
}) => {
  const { t } = useTranslation();
  const id = assessment.name.toLowerCase();
  const current = data.find(
    (item) =>
      item.providerId === providerId && item.assessmentId === assessment.id,
  );
  const [shouldSelectReport, setShouldSelectReport] = useState(false);

  const availableReports = assessment.reports.values.map((item) => ({
    id: item.key,
    name: item.value,
  }));

  const optionsOnly = assessment["isVisible"] === false;

  const handleChange = (event, id, name) => {
    const isChecked = event.currentTarget.checked;
    if (availableReports.length > 1 && isEmptyArray(current?.reports) && isChecked) {
      setShouldSelectReport(true);
      updateAction(providerId, id, name, event.currentTarget.checked);
    } else if (availableReports.length === 1 && isEmptyArray(current?.reports) && isChecked) {
      setShouldSelectReport(false);
      updateAction(providerId, id, name, event.currentTarget.checked, null, [availableReports[0].id]);
    } else {
      setShouldSelectReport(false);
      updateAction(providerId, id, name, event.currentTarget.checked);
    }
  };

  const handleOptionsChange = (event, id, name) => {
    updateOptionsAction(
      providerId,
      id,
      name,
      event.currentTarget.checked,
      event.currentTarget.value,
      event.currentTarget.type,
      optionsOnly,
    );
  };

  const handleSelectChange = (selectedItems, id, name) => {
    setShouldSelectReport(isEmptyArray(selectedItems));
    updateReportsAction(providerId, id, name, selectedItems);
  };

  return (
    <div className="flex items-center flex-wrap">
      <div
        className={classNames(
          optionsOnly ? "hidden" : "",
          "flex h-6 items-center",
        )}
      >
        <input
          id={id}
          name={id}
          aria-describedby="assessment-description"
          type="checkbox"
          checked={current?.value ?? false}
          className="h-4 w-4 rounded border-gray-300 text-mps-ultramarine focus:ring-mps-ultramarine focus:ring-offset-1 focus:ring-1"
          onChange={(event) =>
            handleChange(event, assessment.id, assessment.name)
          }
          disabled={disabled}
        />
      </div>
      {!optionsOnly && (
        <div className={classNames("ml-3 text-sm leading-6")}>
          <label htmlFor={id} className="font-normal text-gray-900">
            {assessment.name}
          </label>
        </div>
      )}

      {assessment.options && assessment.options.type && (
        <div
          className={classNames(
            assessment["isVisible"] === false
              ? "justify-items-start"
              : "justify-end ml-auto",
            "flex h-6 text-sm",
          )}
        >
          {assessment.options.values.map((value, index) => (
            <div key={`assessment_options_key_${value}`}>
              <input
                type={assessment.options.type}
                id={`${id}_options_${value}`}
                name={`${id}_optionsGroup`}
                value={value ?? ""}
                checked={current?.options?.includes(value)}
                onChange={(event) =>
                  handleOptionsChange(event, assessment.id, assessment.name)
                }
                className={classNames(
                  assessment.options.type === "checkbox"
                    ? "rounded border-gray-300"
                    : "",
                  index === 0 ? "ml-0" : "ml-4",
                  "mr-1 text-mps-ultramarine focus:ring-mps-ultramarine focus:ring-offset-1 focus:ring-1",
                )}
                disabled={disabled}
              />
              <label
                htmlFor={`${id}_options_${value}`}
                className="font-normal text-gray-900"
              >
                {t(
                  `Process.TestsAndMethods.Assessments.Options.${value}`,
                  value,
                )}
              </label>
            </div>
          ))}
        </div>
      )}

      {availableReports && !isEmptyJson(availableReports) && (
        <div
          className={classNames("flex h-10 text-sm justify-end ml-auto w-1/3")}
        >
          {availableReports.length === 1 && (
            <div className="mt-3 font-normal text-gray-900">
              {availableReports[0].name}
            </div>
          )}
          {shouldSelectReport && (
            <div className="mt-4 mr-1">
              <ExclamationTriangleIcon
                aria-hidden="true"
                className={classNames("ml-4 h-5 w-5 text-red-700")}
              />
            </div>
          )}
          {availableReports.length > 1 && (
            <MultiSelectBox
              id={`${id}_reports_`}
              options={availableReports}
              required={false}
              action={(event) =>
                handleSelectChange(event, assessment.id, assessment.name)
              }
              selectedItems={current?.reports || []}
              nothingSelectedLabel={t("Process.Actions.SelectReport")}
              disabled={disabled}
            />
          )}
        </div>
      )}
    </div>
  );
};
