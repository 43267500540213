import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from "i18next-http-backend";

i18n
    .use(backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: "fi",
        fallbackLng: "fi",
        debug: false,
        preload: ['fi'],
        interpolation: {
            escapeValue: false,
        }
    });
