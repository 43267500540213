const initialForm= {
    candidateId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    languageCode: "fi",
};

export function cloneCandidate(candidate) {
    let data = JSON.parse(JSON.stringify(initialForm));

    Object.keys(candidate).forEach(function(key) {
        data[key] = candidate[key];
    });

    return data;
}
