import { useTranslation } from "react-i18next";
import {
  formatAsLocalDate,
  formatTime,
  isNotBlank,
} from "../../utils/uiUtils";

export function Interview({ interview, office }) {
  const { t } = useTranslation();

  const interviewAvailable = isNotBlank(interview.date) && (interview.type === "Teams" || interview.type === "Office");

  const interviewTranslationKey = `Process.Properties.${interview.type}`;

  return (
    <>
      {interviewAvailable && (
        <>
          <div className="font-medium text-gray-900">
            {formatAsLocalDate(interview.date)} {t("Generic.Time")}{" "}
            {formatTime(interview.time)}
          </div>
          <div className="mt-1 text-gray-500">
            {t(interviewTranslationKey, interview.type)}
            { interview.language && (
                <span> | {interview.language}</span>
            )}
            {interview.type === "Office" && (
              <div className="mt-1 text-gray-500">{office}</div>
            )}
          </div>
        </>
      )}
      {!interviewAvailable && <div className="font-medium text-gray-900">-</div>}
    </>
  );
}
