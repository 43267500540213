import React from "react";
export function Tooltip({ messages, children }) {
  return (
    <div className="relative flex flex-col items-start group font-medium text-gray-900">
      {children}

      <div className="absolute bottom-0 flex-col items-start hidden mb-6 group-hover:flex truncate">
        <span className="relative z-10 p-2 text-xs leading-tight text-white whitespace-normal bg-mps-ultramarine shadow-lg rounded-md">
          {messages.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
        <div className="w-3 h-3 -mt-2 ml-2 rotate-45 bg-mps-ultramarine"></div>
      </div>
    </div>
  );
}
