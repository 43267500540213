import React from "react";
import { useTranslation } from "react-i18next";
import { InputPrefix } from "./InputPrefix";

const translationsBase = [
  { lang: "fi", value: "" },
  { lang: "sv", value: "" },
  { lang: "en", value: "" },
];

export const Assessment = ({ assessment, action }) => {
  const { t } = useTranslation();
  const title = assessment.translations.title ?? translationsBase;
  const description = assessment.translations.description ?? translationsBase;

  const titleChange = (lang, value) => {
    const index = title.findIndex((t) => t.lang === lang);
    const updatedTitle = title.map((t, i) => {
      return i === index ? { ...t, value: value } : t;
    });

    action(assessment.assessmentId, {
      title: updatedTitle,
      description: description,
    });
  };

  const descriptionChange = (lang, value) => {
    const index = description.findIndex((d) => d.lang === lang);
    const updatedDescription = description.map((d, i) => {
      return i === index ? { ...d, value: value } : d;
    });

    action(assessment.assessmentId, {
      title: title,
      description: updatedDescription,
    });
  };

  return (
    <div>
      <h1 className="my-4">{assessment.name}</h1>
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-2">
          <p className="text-sm">{t("Translations.Assessments.Title")}</p>
          {translationsBase.map((item) => (
            <InputPrefix
              key={`assessment_${assessment.assessmentId}_title_${item.lang}`}
              id={`assessment_${assessment.assessmentId}_title_${item.lang}`}
              lang={item.lang}
              value={title.find((t) => t.lang === item.lang).value}
              action={titleChange}
            />
          ))}
        </div>
        <div className="col-span-3">
          <p className="text-sm">{t("Translations.Assessments.Description")}</p>
          {translationsBase.map((item) => (
            <InputPrefix
              key={`assessment_${assessment.assessmentId}_description_${item.lang}`}
              id={`assessment_${assessment.assessmentId}_description_${item.lang}`}
              lang={item.lang}
              value={description.find((t) => t.lang === item.lang).value}
              action={descriptionChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
