import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { TextSearch } from "./textSearch/TextSearch";
import { AssessmentLog } from "./assessmentLog/AssessmentLog";
import { useUserContext } from '../UserContextProvider';
import Tabs from "../components/Tabs";

export function Search() {
  const { t } = useTranslation();
  const { hasAdminOrCoordinatorRole } = useUserContext();

  const [activeTab, setActiveTab] = useState(1);

  let tabs = [
    { name: t("Search.TextSearch.Title"), current: activeTab === 1, index: 1 }
  ];

  if (hasAdminOrCoordinatorRole) {
    tabs = tabs.concat({
      name: t("Search.AssessmentLog.Title"),
      current: activeTab === 2,
      index: 2,
    });
  }

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    window.scrollTo(0, 0);
  };

  return (
    <AuthenticatedTemplate>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <div className="space-y-10">
            <Tabs tabs={tabs} action={handleTabClick} showIcon={false} />
            {activeTab === 1 && <TextSearch />}
            {activeTab === 2 && <AssessmentLog />}
          </div>
        </div>
      </div>
    </AuthenticatedTemplate>
  );
}
