import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCompletedCandidateProcessAssessments } from "../../api";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Attachment } from "../../components/Attachment";
import AssessmentReport from "../../components/AssessmentReport";
import {
  dateTimeToLocalDate,
  isEmptyArray,
  isEmptyJson
} from "../../utils/uiUtils";
import { parenthesizeIfNotEmpty } from "../../process/ProcessUtils";

export const CandidateProgress = ({ candidateId }) => {
  const { t } = useTranslation();

  const [assessmentsLoading, setAssessmentsLoading] = useState(true);
  const [assessmentResults, setAssessmentResults] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await getCompletedCandidateProcessAssessments(candidateId)
        .then((response) => {
          setAssessmentResults(response);
        })
        .catch((error) => {
          console.log(error);
          setAssessmentResults({});
        })
        .finally(() => {
          setAssessmentsLoading(false);
        });
    };
    if (assessmentsLoading) {
      fetchData().catch(console.error);
    }
  }, [candidateId, assessmentResults, assessmentsLoading]);

  return (
    <div className="col-span-1 gap-x-8 gap-y-10 border-b border-gray-900/10 bg-white shadow-sm ring-2 ring-gray-900/5 sm:rounded-xl w-full">
      <div className="w-full text-base flex items-center justify-between pl-4 pr-4 pt-4">
        <div className="container mx-auto w-full divide-y">
          <div className="flex mb-4 gap-x-2 text-sm text-gray-700">
            <div>
              <h3 className="text-base font-bold leading-7 text-gray-900 sm:truncate sm:text-base sm:tracking-tight">
                {t("Candidate.TestResults.Assessments")}
              </h3>
              {assessmentsLoading ? (
                <LoadingIndicator type="small" />
              ) : isEmptyArray(assessmentResults.assessments) ? (
                <div className="mt-2">
                  {t("Candidate.TestResults.NoTestResults")}
                </div>
              ) : (
                <ul className="list-disc list-inside text-gray-700 mt-2 pl-2">
                  {assessmentResults.assessments.map((assessment) => (
                    <li
                      key={`applicant_assessment_key_${assessment.id}`}
                      className="list-item justify-between py-1 pl-0 pr-1 text-sm"
                    >
                      {assessment.provider}: {assessment.name}{" "}
                      {parenthesizeIfNotEmpty(assessment.options)} (
                      {dateTimeToLocalDate(assessment.completedAt)})
                      {!isEmptyJson(assessment.reports) &&
                        assessment.reports.map((report, index) => (
                          <div
                            className="pl-4"
                            key={`report_${assessment.id}_key_${index}`}
                          >
                            <AssessmentReport report={report} />
                          </div>
                        ))}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="flex mb-4 gap-x-2 text-sm text-gray-700">
            <div className="mt-2">
              <h3 className="text-base font-bold leading-7 text-gray-900 sm:truncate sm:text-base sm:tracking-tight mt-2 mb-1">
                {t("Candidate.TestResults.AssessmentReports")}
              </h3>
              {!assessmentsLoading && isEmptyArray(assessmentResults.assessmentReports) ? (
                <div>{t("Candidate.TestResults.NoTestResults")}</div>
              ) : (
                <div>
                  {assessmentResults.assessmentReports &&
                    assessmentResults.assessmentReports.map((report, index) => (
                      <div
                        className="text-sm mt-1 group flex items-start"
                        key={`assessment_report_${index}_key`}
                      >
                        <Attachment item={report} />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
