import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getPdfDownloadOptions } from "../api";
import { Button } from "./Button";
import { LoadingIndicator } from "./LoadingIndicator";
import { isNotBlank } from "../utils/uiUtils";

export const PDFPreviewModal = ({ fileUrl, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [pdfData, setPdfData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadError = t("Reports.LoadingFailed");

  const closeButtonClicked = (event) => {
    event.preventDefault();
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const options = await getPdfDownloadOptions(fileUrl);
        await fetch(fileUrl, options).then(async (response) => {
          if (response.ok) {
            const blob = await response.blob();
            const data = URL.createObjectURL(blob);
            setPdfData(data);
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        });
      } catch (error) {
        console.error("Fetching PDF failed: ", error);
        setError(loadError);
      } finally {
        setIsLoading(false);
      }
    };
    if (isLoading) {
      fetchPDF().catch((error) => {
        console.error(error);
        setError(loadError);
      });
      // Clean up URL.createObjectURL memory leak
      return () => pdfData && URL.revokeObjectURL(pdfData);
    }
  }, [fileUrl, isLoading, error, pdfData, loadError]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden min-w-screen h-[90vh] rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-2xl sm:p-6">
                <div className="sm:flex sm:flex-row-reverse">
                  <Button
                    text={t("Actions.Close")}
                    action={closeButtonClicked}
                  />
                </div>
                <div>
                  {isLoading ? (
                    <div className="pt-10 text-center sm:mt-5">
                      <LoadingIndicator />
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t("Reports.Loading")}
                        </p>
                      </div>
                    </div>
                  ) : isNotBlank(error) ? (
                    <div>{error}</div>
                  ) : (
                    <div className="embed-responsive pt-4 h-[90vh]">
                      <object
                        id="myPdf"
                        type="application/pdf"
                        height="92%"
                        width="100%"
                        data={pdfData}
                      >
                        {loadError}
                      </object>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
